<template>
<picture class="picture">
  <source v-if="lazyLoad" class="lazy-source" :data-srcset="url" />
  <source v-else :srcset="url" />
  <img v-if="lazyLoad" class="lazy-img" :class="{'rounded': rounded}" src="/img/images/loading.jpg" :data-src="fallback" :alt="alt" :width="width" :height="height" />
  <img v-else class="loaded" :class="{'rounded': rounded}" :src="fallback" :alt="alt" :width="width" :height="height" />
</picture>
</template>

<script>
export default {
  name: 'Picture',
  props: {
    alt: String,
    url: String,
    fallback: String,
    width: Number,
    height: Number,
    lazyLoad: {
      type: Boolean,
      default: true
    },
    rounded: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
img, picture {
  position: relative;
  display: block;
}
img {
  max-width: 100%;
  height: auto;
  width: 100%;
  max-height: 100%;

  &.loaded {
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
  }

  &.rounded {
    border-radius: 50%;
  }

  &:not(.loaded) {
    animation: fadeIn 3s cubic-bezier(1, 0.885, 0.32, 1) alternate infinite ;
    -webkit-animation: fadeIn 3s cubic-bezier(1, 0.885, 0.32, 1) alternate infinite;
    -moz-animation: fadeIn 3s cubic-bezier(1, 0.885, 0.32, 1) alternate infinite;
    -o-animation: fadeIn 3s cubic-bezier(1, 0.885, 0.32, 1) alternate infinite;
    -ms-animation: fadeIn 3s cubic-bezier(1, 0.885, 0.32, 1) alternate infinite;
  }
}
</style>
