<template>
  <div class="social">
    <a href="https://www.facebook.com/Toneel-Leven-is-Streven-Waltwilder-110672407152389/"
      target="_blank" rel="nofollow" title="Toneel Waltwilder facebook pagina">
      <i class="fab fa-facebook-f"></i>
    </a>
  </div>
</template>

<style lang="scss" scoped>
.social i {
  font-size: 1.5em;
  color:#ccc;
}
.social i:hover {
  color: #999;
  cursor: pointer;
}
</style>
