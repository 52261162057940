<template>
  <div class="notification" :class="notification.type">
    <div class="c-holder flex-ib">
      <span class="message" v-if="notification.message?.length > 0">{{ notification.message }}</span>
      <router-link v-if="notification.redirect" :to="notification.redirect">Lees meer</router-link>
      <span class="close" @click="close">x</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    notification: Object
  },
  methods: {
    close () {
      this.$emit('close', this.notification?.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.close {
  font-size: 1.5em;
  cursor: pointer
}

.notification {
  padding: 1em;

  &.error {
    background-color:#fd2525;
    color:#FFF
  }
}
</style>
