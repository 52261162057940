<template>
  <div class="field">
    <input
        :autocomplete="autoFill ? 'on' : 'off'"
        :id="name"
        :type="type"
        :name="name"
        :value="modelValue"
        :class="{ filled: modelValue.length > 0 }"
        @input="$emit('update:modelValue', $event.target.value)"
    />
    <label :for="name">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'TextInputField',
  props: {
    autoFill: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      required: true
    },
    suffix: {
      type: String,
      required: false
    },
    modelValue: {
      type: String,
      required: true
    }
  },
  computed: {
    name () {
      let result
      const brackedindex = this.label.indexOf('(')
      if (brackedindex > -1) {
        result = this.label.substring(0, brackedindex - 1)
          .toLowerCase().replace(' ', '-')
      } else {
        result = this.label.toLowerCase().replace(' ', '-')
      }

      if (this.suffix != null) {
        return result + `-${this.suffix}`
      } else {
        return result
      }
    }
  }
}
</script>

<style src="@/forms.css"></style>
