<template>
  <header class="shad-o">
    <div class="c-holder flex-ib">
      <div id="h-logo">
        <img src="/img/images/logo.jpg" alt="Leven is streven logo toneel-waltwilder" width="122" height="124" />
      </div>
      <nav class="flex">
        <router-link :to="{ name: 'Home' }">
          <span>Voorstellingen</span>
        </router-link>
        <!-- <router-link :to="{ name: 'Albums' }">
          <span>Albums</span>
        </router-link> -->
        <router-link :to="{ name: 'Contact' }">
          <span>Contact</span>
        </router-link>
      </nav>
    </div>
  </header>
</template>

<style lang="scss" scoped>
header {
  background-color: #edead8;
}
#h-logo img {
  display: block;
  width: auto;
  height: 70px;
  padding: 8px;
}
a {
  display: block;
  text-decoration: none;
  padding: 0 .5em;
  line-height: 80px;

  color: #222;

  &:hover span, &.router-link-exact-active span {
    color: #222;
    padding: 4px 0;
    border-bottom: 2px solid #222;
  }
}

@media screen and (max-width: 500px) {
  #h-logo {
    height: 54px;
  }
  .c-holder {
    display: block;
  }

  nav {
    display: block;

    a {
      display: block;
      padding: .5em 1em;
    }
  }
}
</style>
