<template>
<div class="min-h flex-cc">
  <div id="contact" class="block pad-1">
    <div class="text-c">
      <h1>Contact</h1>
      <p>Heeft u vragen over onze evenementen? Wilt u tickets reserveren?
        Neem dan gerust contact op met Anne-Marie Vanoppen via onderstaande gegevens.</p>
    </div>
    <div id="details">
      <h2>Gegevens</h2>
      <span class="name">Anne-Marie Vanoppen</span>
      <span><a class="value" href="tel:+32497878567">0474 05 11 45</a></span>
      <a class="value" href="mailto:raymondduelen@hotmail.com">anne-marievanoppen@hotmail.com</a>
    </div>
    <form v-if="false">
      <h2>Formulier</h2>
      <text-input-field label="Naam" v-model="form.name" />
      <text-input-field label="Email" v-model="form.email" :autoFill="true" />
      <text-input-field label="Onderwerp" v-model="form.subject" />
      <text-area-field label="Bericht" v-model="form.message" />
      <div class="field">
        <input type="submit" value="Verzend" />
      </div>
    </form>
  </div>
</div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'

import TextInputField from '../components/inputs/TextInputField.vue'
import TextAreaField from '../components/inputs/TextAreaField.vue'

export default {
  components: {
    TextInputField,
    TextAreaField
  },
  setup () {
    return {
      form: reactive({
        name: ref(''),
        email: ref(''),
        subject: ref(''),
        message: ref('')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.text-c {
  text-align: center;
}
h2 {
  margin: .75em 0 .25em;
}

#details {
  span {
    display: block;
  }

  .name {
    font-size: 1.2em;
  }
}

@media screen and (min-width: 600px) {
  #contact {
    width: 500px;
    margin: 1em 0;
  }
}
</style>
