<template>
  <div class="home">
    <div class="banner">
      <picture>
        <source srcset="/img/images/theater.webp" />
        <img src="/img/images/theater.jpg" alt="Toneel waltwilder theater background" width="2048" height="1362" />
      </picture>
      <div class="cover-text">
        <div class="c-holder">
          <h1>BOTER BIJ DE VIS</h1>
          <div></div>
          <span>Eric Rutten heeft een ingenieus plan bedacht om zijn dalende inkomsten te compenseren</span>
        </div>
      </div>
    </div>
    <div>
      <div class="c-holder">
        <h1 class="pad-0-5"><i class="fas fa-exclamation"></i>NIEUWS</h1>
      </div>
      <div id="update-banner">
        <img src="/img/images/image_2.jpg"
          width="871" height="911" alt="Covid19" />
      </div>
    </div>
    <div class="c-holder">
      <h1 class="pad-0-5"><i class="fas fa-theater-masks"></i>VOORSTELLINGEN</h1>
      <div v-if="events?.length > 0" id="events">
        <template v-for="event in events">
          <event v-if="showArchive || !event.archived" :key="event.id" :event="event" class="event" />
        </template>
      </div>
      <div class="flex-c pad">
        <button class="button" @click="showArchive = !showArchive">Archief</button>
      </div>
      <h1 class="pad-0-5"><i class="fas fa-users"></i>BESTUUR</h1>
      <div v-if="staff?.length > 0" id="members" class="flex-c">
        <div v-for="member in staff" :key="member.id" class="member pad">
          <Picture class="m-picture" :alt="member.firstName + ' ' + member.lastName + ' profile picture'" :url="member.image.url" :lazyLoad="false"
            :fallback="member.image.urlFallback" :width="member.image.width" :height="member.image.height" :rounded="true" />
          <span class="name">{{ member.firstName }} {{ member.lastName }}</span>
          <span class="function">{{ member.function }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'

import Event from '@/components/Event.vue'
import Picture from '@/components/album/Picture.vue'

export default {
  components: {
    Event,
    Picture
  },
  name: 'Home',
  setup () {
    return {
      events: reactive([]),
      staff: reactive([]),
      showEventsError: ref(false),
      showStaffError: ref(false),
      showArchive: ref(false)
    }
  },
  mounted () {
    fetch('/assets/events.json')
      .then(async (x) => {
        if (x.ok) {
          this.events.push(...(await x.json()))
          this.showStaffError = false
        } else {
          this.showEventsError = true
        }
      }).catch(_ => { this.showEventsError = true })

    fetch('/assets/staff.json')
      .then(async (x) => {
        if (x.ok) {
          this.staff.push(...(await x.json()))
          this.showStaffError = false
        } else {
          this.showStaffError = true
        }
      }).catch(_ => { this.showStaffError = true })
  }
}
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  height: 100%;

  picture {
    width: 100%;

    img {
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .cover-text {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 2em);
    padding: 0 1em;

    text-align: center;

    h1, span {
      display: inline-block;
      color:#FFF;
      background-color:rgba(0,0,0,0.6);
    }

    h1 {
      font-weight: 900;
      font-size: 2.5em;
      padding: .3em;
      margin: .5em 0;
    }

    span {
      padding: .5em;
      margin: 0;
    }
  }
}

.button {
  padding: .75em 1.25em;
  font-size: 1.3em;
  outline: 0;
  border: 0;
  background-color:#222222;
  color: #e1e1e1;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    background-color:#424242;
  }
}

#members {
  display: flex;
  flex-wrap: wrap;

  .member {
    flex: 0 1 300px;
    margin: calc(90px + .5em) .5em .5em;
    text-align: center;

    background-color:#FFF;
    border-radius: .5em;

    .name {
      display: block;
      font-size: 1.1em;
    }
    .function {
      display: block;
      color:#444;

      font-size: 0.85em;
      text-transform: uppercase;
      margin-bottom: 1em;
    }

    .m-picture {
      width: 180px;
      margin: calc(-90px - 1em) auto 0;
      border-radius: 50%;
      border: .4em solid #FFF;
    }
  }
}

#update-banner {
  background-color:#256f62;
  padding: .5em 0;

  img {
    max-width: 500px;
    width: 100vw;
    height: auto;
    margin: 0 auto 0;
    display: block
  }
}

@media screen and (max-width: 350px) {
  h1 {
    font-size: 1.5em
  }
}

@media screen and (max-width: 799px) {
  .banner, .banner img {
    width: 100%;
    height: 20em;
  }

  .banner .cover-text {
    padding-top: 5em;

    font-size: .8em;
  }
}

@media screen and (min-width: 800px) {
  .banner, .banner img {
    width: 100%;
    height: 40em;
  }

  .banner .cover-text {
    padding-top: 15em;
  }
}
</style>
