<template>
<div class="album">
  <h2>{{ album.name }}</h2>
  <div class="pictures" ref="picturesElement">
    <div class="preview" v-for="picture in album.pictures" :key="picture.id">
      <Picture :alt="picture.name + ' preview'" :url="picture.preview.url" :fallback="picture.preview.urlFallback"
        :width="picture.preview.width" :height="picture.preview.height" @click="showFullImage(picture)" />
      <span class="title pad">{{ picture.name }}</span>
    </div>
    <div v-if="fPicture" class="fullscreen-image flex-cc" @click="closeFullImage($event)">
      <Picture :alt="fPicture.name" :url="fPicture.image.url" :fallback="fPicture.image.urlFallback"
        :width="fPicture.image.width" :height="fPicture.image.height" :lazyLoad="false" />
      <div class="f-close">x</div>
    </div>
  </div>
</div>
</template>

<script>
import { ref } from '@vue/reactivity'
import Picture from './Picture.vue'

export default {
  components: {
    Picture
  },
  props: {
    album: Object
  },
  setup () {
    return {
      fPicture: ref(null),
      fClosing: ref(false),
      picturesElement: ref(null),
      observers: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      if ('IntersectionObserver' in window &&
        'IntersectionObserverEntry' in window &&
        'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
        const imgObserver = new IntersectionObserver((entries, self) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.loadImage(entry.target)
              self.unobserve(entry.target)
            }
          })
        })
        this.observers.push(imgObserver)

        this.picturesElement.querySelectorAll('.lazy-img').forEach(img => imgObserver.observe(img))
      } else {
        this.picturesElement.querySelectorAll('.lazy-img').forEach(img => this.loadImage(img))
      }
    })
  },
  beforeUnmount () {
    this.observers.forEach(x => x.disconnect())
  },
  methods: {
    loadImage (image) {
      const picture = image.parentElement
      const source = picture.querySelector('.lazy-source')

      source.srcset = source.getAttribute('data-srcset')
      source.removeAttribute('data-srcset')
      image.onload = () => { image.classList.add('loaded') }
      image.src = image.getAttribute('data-src')
      image.removeAttribute('data-src')
    },
    showFullImage (picture) {
      this.fClosing = false
      this.fPicture = picture
    },
    closeFullImage (event) {
      if (event.target instanceof HTMLImageElement) {
        return
      }

      this.fPicture = null
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  padding: .5em;
}

.album {
  z-index: 1;

  .pictures {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.preview {
  position: relative;
  flex: 0 1 333px;
  margin: .5em;
  cursor: pointer;

  .title {
    display: none;
    position: absolute;
    bottom: 2px;
    width: calc(100% - 2em);
    background-color: rgba(0,0,0,0.5);
    -webkit-box-shadow: 0px -2px 4px 0px rgba(0,0,0,0.52);
            box-shadow: 0px -2px 4px 0px rgba(0,0,0,0.52);
    color:#FFF;
    pointer-events: none;
  }

  &:hover .title {
    display: block;
  }
}

.fullscreen-image {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 10;
  width: 100%;
  height: 100vh;

  background-color:rgba(0,0,0,0.9);

          animation: fadeIn .5s;
  -webkit-animation: fadeIn .5s;
     -moz-animation: fadeIn .5s;
       -o-animation: fadeIn .5s;
      -ms-animation: fadeIn .5s;

  .picture {
    cursor: default;
    display: block;
  }
}

.f-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: .5em;
  color: #FFF;
  font-size: 3em;
  line-height: .6em;
  cursor: pointer;
}

@media screen and (max-width: 1429px) {
  .album {
    justify-content: space-evenly;
  }
}
@media screen and (min-width: 800px) {
  .fullscreen-image .picture {
    padding: 4em;
  }
}
@media screen and (max-width: 600px) {
  .preview {
    flex: 1 1 400px;
  }
}
</style>
