<template>
  <footer class="pad-1">
    <div class="c-holder flex-ib">
      <span id="copyrights">&copy; {{ currentYear }} - Leven is Streven</span>
      <Social />
    </div>
  </footer>
</template>

<script>
import Social from './Social'

export default {
  name: 'Footer',
  components: {
    Social
  },
  computed: {
    currentYear () {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  background-color: #222;
  color: #ccc;
}
#copyrights {
  font-size: .75em;
}
</style>
