<template>
  <div class="event pad">
    <h2>
      <span>{{ event.name.toUpperCase() }}</span>
      <span class="h2-tag">
        <!-- <i class="fas fa-archive"></i> -->
        <span v-if="event.year">({{ event.year }})</span>
      </span>
    </h2>
    <div class="preview flex">
      <p>{{ event.description }}</p>
      <iframe class="shad-o-2" :src="event.video" loading="lazy" style="border: none;" allow="accelerometer; gyroscope; encrypted-media;" allowfullscreen="true"></iframe>
    </div>
    <div class="crew flex flex-wrap">
      <ul v-for="(crew, name) in event.crew" :key="name">
        <li><b class="heading">{{ name }}</b></li>
        <li v-for="(name, nickname) in crew" :key="nickname">
          <b>{{ nickname }}</b>: {{ name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Event',
  props: {
    event: Object,
    odd: Boolean
  }
}
</script>

<style lang="scss" scoped>
h2 {
  margin: 0;
  font-size: 1.2em;
}

.h2-tag {
  margin: 0 .5em;
  font-size: .6em;
  color:rgb(117, 117, 117);
}

.event {
  .preview {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;

    p {
      flex: 1;
      margin: .5em 1em .5em 0;
      text-align: center;
    }
    iframe {
      height: 281px;
      max-width: 500px;
      width: 100%;
      border-radius: .5em
    }

  }

  .crew {
    justify-content: center;
    ul {
      list-style: none;
      padding: 0 .5em;

      .heading {
        font-size: 1.3em;
      }

      li:first-child {
        padding-bottom: .5em;
      }
    }
  }
}

@media screen and (min-width:1025px) {
  .event .preview {
    flex-direction: row;
  }
}
</style>
