<template>
  <div class="field">
    <textarea
        :id="name"
        :name="name"
        :value="modelValue"
        :class="{ filled: modelValue.length > 0 }"
        :rows="rows"
        @input="$emit('update:modelValue', $event.target.value)"
    ></textarea>
    <label :for="name">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'TextAreaField',
  props: {
    label: {
      type: String,
      required: true
    },
    rows: {
      type: Number,
      default: 4
    },
    modelValue: {
      type: String,
      required: true
    }
  },
  computed: {
    name () {
      return this.label.toLowerCase().replace(' ', '-')
    }
  }
}
</script>
