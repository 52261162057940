<template>
  <Notification v-for="item in notifications" :key="item.id"
    :notification="item" @close="hideNotification" />
  <Header />
  <section id="page-contents">
    <router-view/>
  </section>
  <Footer />
</template>

<script>
import { ref } from '@vue/reactivity'

import Header from './components/Header'
import Footer from './components/Footer'
import Notification from './components/Notification'

export default {
  components: {
    Header,
    Footer,
    Notification
  },
  setup () {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then(x => {
        for (const registration of x) {
          registration.unregister()
        }
      })
    }
    return {
      notifications: ref([])
    }
  },
  mounted () {
    fetch('./assets/notifications.json')
      .then(async (x) => {
        if (x.ok) {
          const hidden = await this.$store.dispatch('getHiddenNotifications') || []
          let notifications = await x.json()
          notifications = notifications.filter(x => hidden.findIndex(y => y === x.id) === -1)
          this.notifications.push(...notifications)
        }
      })
  },
  methods: {
    hideNotification (id) {
      this.notifications = this.notifications.filter(x => x.id !== id)
      this.$store.dispatch('hideNotification', id)
    }
  }
}
</script>

<style lang="scss">
body {
  padding:0;
  margin:0;
  font-family: 'Open Sans', Helvetica, sans-serif;
  background-color:#f0f0f0;
  --color:#222;
  --accent:#0088ff;
  --accent-hover:#0062b8;
}

.block {
  background-color:#FFF;
}

.c-holder {
  max-width: 1400px;
  margin: 0 auto;
}

h1, h2, h3 {
  font-family: 'Exo 2', sans-serif;
  margin: 0;
}

h1 {
  font-size: 2em;

  i {
    margin-right: .25em;
  }
}

a {
  text-decoration: none;
  color:#0079e4;

  &:hover {
    color: rgb(21, 85, 141);
  }
}

.pad {
  padding: 1em;
}
.pad-1 {
  padding: 1.5em;
}
.pad-0-5 {
  padding: 0.75em;
}

.flex {
  display: flex;
}
.flex-c {
  display: flex;
  justify-content: center;
}
.flex-cc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-ib {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-wrap {
  flex-wrap: wrap;
}

.shad-o-2 {
  -webkit-box-shadow: 0 0 .5em 0 rgba(0,0,0,.2);
      -moz-box-shadow: 0 0 .5em 0 rgba(0,0,0,.2);
          box-shadow: 0 0 .5em 0 rgba(0,0,0,.2);
}

#page-contents, .min-h {
  min-height: calc(100vh - 86px);
  z-index: 1;
  position: relative;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color);
}

@media screen and (min-width: 600px) {
  .block {
    border-radius: 5px
  }
  .block, .shad-o {
    -webkit-box-shadow: 0 0 4px 0 rgba(0,0,0,.2);
       -moz-box-shadow: 0 0 4px 0 rgba(0,0,0,.2);
            box-shadow: 0 0 4px 0 rgba(0,0,0,.2);
  }
  .flex-cc {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@keyframes fadeIn {
  0% {opacity:.2;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:.2;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:.2;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:.2;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:.2;}
  100% {opacity:1;}
}
</style>
