<template>
  <div class="c-holder pad">
    <h1>
      <i class="fas fa-images"></i>
      <span>Albums</span>
    </h1>
  </div>
  <album v-for="album in albums" :key="album.id" :album="album"
    class="c-holder"></album>
</template>

<script>
import { reactive } from '@vue/reactivity'

import Album from '@/components/album/Album.vue'

export default {
  name: 'Albums',
  components: {
    Album
  },
  setup () {
    return {
      albums: reactive([])
    }
  },
  mounted () {
    fetch('/assets/albums.json')
      .then(x => {
        if (x.ok) {
          return x.json()
        }
        return []
      }).then(x => { this.albums.push(...x) })
  }
}
</script>
