import { createStore } from 'vuex'

const LS_HIDDEN_NOTIFICATIONS = 'tw_hidden_notifications'

export default createStore({
  state: {
    // api: 'https://api.toneel-waltwilder.be/',
    api: 'http://localhost:4002/'
  },
  getters: {
    apiUrl (state) { return state.api }
  },
  actions: {
    hideNotification (_, id) {
      const items = JSON.parse(localStorage.getItem(LS_HIDDEN_NOTIFICATIONS)) ?? []
      items.push(id)
      localStorage.setItem(LS_HIDDEN_NOTIFICATIONS, JSON.stringify(items))
    },
    getHiddenNotifications () {
      return JSON.parse(localStorage.getItem(LS_HIDDEN_NOTIFICATIONS))
    }
  }
})
